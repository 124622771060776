import { Box, BoxProps, Grid, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import theme from '../theme/index';
import { Fire } from '../assets';
import PigogoChip from './PigogoChip';

const StyleBox = styled(Box)(() => ({
  boxSizing: 'border-box',
  display: 'inline-flex',
  position: 'relative',
  borderRadius: '24px',
  // background: '#8C3E53',
  '&:before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    background: 'linear-gradient(180deg, #313D53 0%, rgba(49, 61, 83, 0) 100%)',
    borderRadius: '24px',
    zIndex: 100,
  },
  [theme.breakpoints.down('md')]: {
    height: '316px',
    width: '100%',
  },

  [theme.breakpoints.up('md')]: {
    height: '316px',
    width: '320px',
  },
}));

const StyleBoxFillImg = styled(Box)(() => ({
  position: 'absolute',
  zIndex: '99',
  top: 0,
  right: 0,
  height: '100%',
  width: '100%',
}));

interface Props {
  img?: string;
  title: string;
  subTitle: string;
  date?: string;
  hotOffer?: boolean;
  boxProps?: BoxProps;
  onClick: () => void;
}

const PigogoStorePromoImg: FC<Props> = (props) => {
  return (
    <StyleBox {...props.boxProps}>
      {props.img && (
        <StyleBoxFillImg>
          <div
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '24px',
              background: `linear-gradient(180deg, #313D53 0%, #313D5300 100%), url('${props.img}') center no-repeat`,
              backgroundSize: 'cover',
            }}
          />
        </StyleBoxFillImg>
      )}
      <Box style={{ position: 'absolute', padding: 24, width: '100%', height: '100%', zIndex: '100' }}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px' }}>
            <Box
              p={0}
              sx={{
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '2',
                display: '-webkit-box',
                overflow: 'hidden',
              }}
            >
              <Typography
                variant={'body2'}
                style={{
                  color: '#F2A4B9',
                }}
              >
                {props.title}
              </Typography>
            </Box>

            <Box
              p={0}
              mt={2}
              sx={{
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '5',
                display: '-webkit-box',
                overflow: 'hidden',
              }}
            >
              <Typography variant={'body1'} sx={{ color: '#fff', letterSpacing: 0 }}>
                {props.subTitle}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant={'subtitle1SmallR'}
                component="p"
                style={{ lineHeight: '20px', fontWeight: 400, color: '#fff' }}
              >
                {props.date}
              </Typography>
            </Box>
          </Box>
          {!!props.hotOffer && (
            <Box>
              <PigogoChip width={'auto'} size={'small'} color={'secondary'} label={'x2'} startIcon={<Fire />} />
            </Box>
          )}
          {!props.hotOffer && (
            <Grid width={32} height={32}>
              <Box
                width={33}
                height={32}
                borderRadius={24}
                style={{
                  backgroundColor: '#E9688A',
                  paddingBottom: '1px',
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Fire />
              </Box>
            </Grid>
          )}
        </Box>
      </Box>
    </StyleBox>
  );
};

export default PigogoStorePromoImg;
