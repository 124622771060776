import React, { useEffect, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import { PigogoStoreCard, PigogoStorePromo, PigogoStorePromoImg } from 'components';
import { Box, Skeleton, Typography } from '@mui/material';
import InformationStore from '../Stores/InformationStore';
import TipsButton from '../Stores/TipsButton';
import { Promo } from '../../models/Promo';
import { useAppSelector } from '../../redux/hooks';
import { useDeleteFavouriteMutation, usePostFavouriteMutation } from '../../redux/api/mutations/favouritesMutation';
import useQuery from '../../hooks/useQuery';
import { Shop } from '../../models/Shop';
import { setSnackBar } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import PigogoReturnMoney from './PigogoReturnMoney';
import PigogoOfferTagCard from './PigogoOfferTagCard';
import PigogoCouponCard from './PigogoCouponCard';
import { Browser } from '@capacitor/browser';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useAllCategoriesQuery } from '../../redux/api/queries/categoriesQuery';

type StoreDetailPageProps = {
  shop?: Shop;
  shopSlug?: string;
  shopLoad: boolean;
  message: (value: string) => void;
  setLogin: (value: boolean) => void;
  setRedirectModal: (value: boolean) => void;
  footerMessage: (value: string) => void;
  footerRedirLink?: (value: string) => void;
  contentRef?: any;
};

const StoreDesktop: React.FC<StoreDetailPageProps> = ({
  shop,
  shopSlug,
  shopLoad,
  message,
  setLogin,
  setRedirectModal,
  footerMessage,
  footerRedirLink,
  contentRef,
}) => {
  const query = useQuery();
  const router = useIonRouter();
  const dispatch = useDispatch();

  const [postFavourite, { isError: postError, reset: postReset }] = usePostFavouriteMutation();
  const [deleteFavourite, { isError: deleteError, reset: deleteReset }] = useDeleteFavouriteMutation();

  const {
    data: allCategories,
    isLoading: loadingCategories,
    isSuccess,
  } = useAllCategoriesQuery({ filter: 'parents' }, { refetchOnMountOrArgChange: false });

  const [shopState, setShopState] = useState<Shop>();
  const [selectedShop, setSelectedShop] = useState<Shop>();

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const [postOperation, setPostOperation] = useState<boolean>(false);
  const [deleteOperation, setDeleteOperation] = useState<boolean>(false);

  const [favShop, setFavShop] = useState<boolean>(!!shop?.user_favourite);

  useEffect(() => {
    const findId = query.get('offer');
    if (findId && !shopLoad) {
      const element = document.getElementById(findId)?.parentElement?.parentElement?.parentElement;
      setTimeout(() => {
        element?.scrollIntoView({
          behavior: 'smooth',
        });
      }, 1000);
    }
  }, [query, shopLoad]);

  useEffect(() => {
    if (shop) {
      setSelectedShop(shop);
      setFavShop(!!shop.user_favourite);
    }
    if (!loadingCategories && isSuccess) {
      if (
        !shopLoad &&
        !shop &&
        !allCategories?.data.find((category) => category.slug.name === shopSlug) &&
        !!shopSlug
      ) {
        router.push('/404-not-found');
      }
    }
  }, [shop, shopLoad, loadingCategories]);

  const openModalLoginSavings = () => {
    setLogin(true);
    message('Για να κερδίσεις επιστροφή χρημάτων πρέπει πρώτα να συνδεθείς στο Pigogo.');
    footerMessage('Μεταφορά χωρίς επιστροφή χρημάτων');
  };

  const openModalLoginStore = () => {
    setLogin(true);
    message('Για να κερδίσεις επιστροφή χρημάτων πρέπει πρώτα να συνδεθείς στο Pigogo.');
    footerMessage('Μεταφορά χωρίς επιστροφή χρημάτων');
    footerRedirLink && footerRedirLink(shop?.urlWithoutCashback || '');
  };

  const openModalCoupon = () => {
    setLogin(true);
    message('Για να δεις τον κωδικό του κουπονιού πρέπει πρώτα να συνδεθείς στο Pigogo.');
    footerMessage('Μεταφορά χωρίς επιστροφή χρημάτων');
  };

  const handleRedirection = async (url?: string) => {
    setRedirectModal(true);
  };

  const handleClickStore = async (url: string) => {
    await Browser.open({ url: url });
  };

  const favourite = async () => {
    if (!!accessToken || sessionLoggedIn) {
      await setFavouriteDef();
    }
  };

  const setFavouriteDef = async () => {
    if (!shop) {
      return;
    }

    if (!favShop && !postOperation) {
      setPostOperation(true);
      setFavShop(true);

      const data: any = await postFavourite({ shop_id: shop.id, notifications: 1 });
      if (data?.error) {
        const params = { value: true, msg: 'Ανεπιτυχής προσθήκη αγαπημένου!', severity: 'error' };
        dispatch(setSnackBar(params));

        setFavShop(false);
      }

      setPostOperation(false);
    } else if (favShop && !deleteOperation) {
      setDeleteOperation(true);
      setFavShop(false);

      const data: any = await deleteFavourite(shop.id);
      if (data?.error) {
        const params = { value: true, msg: 'Ανεπιτυχής αφαίρεση αγαπημένου!', severity: 'error' };
        dispatch(setSnackBar(params));

        setFavShop(true);
      }

      setDeleteOperation(false);
    }

    return;
  };

  const permissions = () => {
    return (
      <Box display={'flex'} gap={1}>
        <Box>
          <Typography variant={'body2MediumL'} color={'#313D53'}>
            <>
              <ReactMarkdown
                children={selectedShop?.terms || ''}
                rehypePlugins={[rehypeRaw]}
                components={{
                  ol: ({ node, ...props }) => (
                    <ol
                      style={{
                        paddingLeft: '18px',
                        display: 'flex',
                        gap: '16px',
                        flexDirection: 'column',
                        margin: '0 0 8px 0',
                      }}
                      {...{ ...props, ordered: 'true' }}
                    />
                  ),
                  ul: ({ node, ...props }) => (
                    <ul
                      style={{
                        paddingLeft: '18px',
                        display: 'flex',
                        gap: '16px',
                        flexDirection: 'column',
                        margin: '0 0 8px 0',
                      }}
                      {...{ ...props, ordered: 'true' }}
                    />
                  ),
                  a: ({ node, ...props }) => (
                    <a
                      style={{
                        color: '#313D53',
                        textDecorationColor: '#313D53',
                        fontWeight: '400',
                      }}
                      {...props}
                    />
                  ),
                }}
              />
            </>
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box display={'flex'} paddingTop={2} justifyContent={'space-between'} gap={4}>
      {/*Left column*/}
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        {shopLoad && (
          <Box>
            <PigogoStoreCard loading={true} setFavourite={() => ''} handleClick={() => ''} />
          </Box>
        )}
        {selectedShop && !shopLoad && (
          <Box width={'320px'} style={{ position: 'sticky', top: 32, zIndex: 3000 }}>
            <PigogoStoreCard
              logout={!(!!accessToken || sessionLoggedIn)}
              logo={selectedShop?.image}
              favourite={favShop ? true : false}
              cashbackCount={selectedShop.cashback_categories_count > 1}
              discount={`${selectedShop?.cashback}${selectedShop?.currency}`}
              deleteDisc={
                selectedShop?.strikedCashback ? `${selectedShop?.strikedCashback}${selectedShop?.strikedCurrency}` : ''
              }
              setFavourite={() => favourite()}
              handleClick={!!accessToken || sessionLoggedIn ? handleRedirection : openModalLoginStore}
              url={selectedShop?.url}
              loggedIn={sessionLoggedIn || !!accessToken}
            />
          </Box>
        )}

        {selectedShop &&
          !shopLoad &&
          selectedShop.promos &&
          selectedShop.promos.map((promo: Promo, index: number) => {
            return promo.image ? (
              <Box key={index}>
                <PigogoStorePromoImg
                  img={promo.image}
                  title={promo.box_type_copy || ''}
                  subTitle={promo.box_title || ''}
                  onClick={() => handleClickStore(promo.url)}
                  hotOffer={promo.is_double}
                  date={promo.box_subtitle || ''}
                />
              </Box>
            ) : (
              <Box key={index} width={'320px'}>
                <PigogoStorePromo
                  title={promo.box_type_copy || ''}
                  subTitle={promo.box_title || ''}
                  onClick={() => handleClickStore(promo.url)}
                  boxProps={{
                    sx: {
                      width: '100%',
                      background: `linear-gradient(180deg, #313D53 0%, #313D5300 100%), ${promo.color || '#313D5300'}`,
                    },
                  }}
                  hotOffer={promo.is_double}
                  date={promo.box_subtitle || ''}
                />
              </Box>
            );
          })}
        <Box>
          {shopLoad ? (
            <InformationStore
              loading={true}
              speedTitle={`${selectedShop?.stats_tracking_speed}`}
              percent={`${selectedShop?.stats_payment_accuracy}`}
              week={`${selectedShop?.stats_payment_speed}`}
            />
          ) : (
            <InformationStore
              speedTitle={`${selectedShop?.stats_tracking_speed}`}
              percent={`${selectedShop?.stats_payment_accuracy}`}
              week={`${selectedShop?.stats_payment_speed}`}
            />
          )}
        </Box>
        {!shopLoad && (
          <Box>
            <TipsButton url={'/return-tips'} />
          </Box>
        )}
      </Box>

      {/*Right column*/}
      <Box display={'flex'} flexDirection={'column'} gap={5} pt={1} flexGrow={1}>
        {/*First section*/}
        <Box display={'flex'} flexDirection={'column'} gap={2} paddingX={4} pb={1}>
          <Box>
            {shopLoad ? (
              <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'30%'} />
            ) : (
              <Typography variant="large900" component="h3" color={'#1D2532'}>
                Περιγραφή καταστήματος
              </Typography>
            )}
          </Box>
          <Box>
            {shopLoad ? (
              <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'100%'} />
                <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'100%'} />
                <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'100%'} />
              </Box>
            ) : (
              <Typography variant="body2MediumL" component="p" color={'#313D53'}>
                {selectedShop?.description}
              </Typography>
            )}
          </Box>
        </Box>

        {/*Second section*/}
        {shopLoad && (
          <Box display={'flex'} flexDirection={'column'} gap={2} paddingX={4}>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Skeleton animation="wave" style={{ background: '#EAECEE', borderRadius: '16px' }} width={'30%'} />
              <Skeleton
                animation="wave"
                style={{ background: '#EAECEE', borderRadius: '16px', height: '60px' }}
                width={'100%'}
              />
              <Skeleton
                animation="wave"
                style={{ background: '#EAECEE', borderRadius: '16px', height: '60px' }}
                width={'100%'}
              />
            </Box>
          </Box>
        )}
        {!shopLoad && !!selectedShop?.terms && (
          <Box display={'flex'} flexDirection={'column'} gap={2} paddingX={4}>
            <Typography variant="large900" component="h3" color={'#1D2532'}>
              Όροι & προϋποθέσεις
            </Typography>
            {permissions()}
          </Box>
        )}

        {/*Third section*/}
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          {selectedShop && selectedShop.cashback_categories && shopLoad && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={2}
              px={4}
              py={5}
              sx={{ backgroundColor: '#FEF6F1', borderRadius: '24px' }}
            >
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Skeleton
                  animation="wave"
                  width={'30%'}
                  height={'24px'}
                  sx={{ backgroundColor: '#EAECEE', borderRadius: '24px' }}
                ></Skeleton>
                <Skeleton
                  animation="wave"
                  width={'48px'}
                  height={'48px'}
                  sx={{ backgroundColor: '#EAECEE', borderRadius: '24px' }}
                ></Skeleton>
              </Box>
              <Box display={'flex'} justifyContent={'flex-start'} gap={2} alignItems={'center'}>
                <Skeleton
                  animation="wave"
                  width={'80px'}
                  height={'40px'}
                  sx={{ backgroundColor: '#EAECEE', borderRadius: '24px' }}
                ></Skeleton>
                <Skeleton
                  animation="wave"
                  width={'48px'}
                  height={'40px'}
                  sx={{ backgroundColor: '#EAECEE', borderRadius: '24px', flexGrow: 1 }}
                ></Skeleton>
              </Box>
            </Box>
          )}
          {selectedShop && selectedShop.cashback_categories && !shopLoad && (
            <Box>
              <PigogoReturnMoney data={selectedShop.cashback_categories} />
            </Box>
          )}

          {shopLoad && (
            <Box>
              <PigogoOfferTagCard loading={true} />
            </Box>
          )}
          {selectedShop?.savings?.offers && !shopLoad && (
            <Box>
              <PigogoOfferTagCard
                title={'Προσφορές καταστήματος'}
                data={selectedShop?.savings?.offers}
                handleButtonOffer={() =>
                  !!accessToken || sessionLoggedIn ? handleRedirection() : openModalLoginSavings()
                }
                footerRedirLink={footerRedirLink}
              />
            </Box>
          )}
          {selectedShop?.savings?.coupons && !shopLoad && (
            <Box>
              <PigogoCouponCard
                title={'Κουπόνια καταστήματος'}
                data={selectedShop?.savings?.coupons}
                logout={!(!!accessToken || sessionLoggedIn)}
                setRedirectModal={() => setRedirectModal(true)}
                setLogin={() => openModalCoupon()}
                footerRedirLink={footerRedirLink}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default StoreDesktop;
